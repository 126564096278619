/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:066de0c0-e869-4d2b-9d77-862eff340121",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_m8QfzL1c5",
    "aws_user_pools_web_client_id": "56nrv6nc6gpclke4tvu3608ljm",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://6jjbqwvbkzhu7dq2gvis4up6fu.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "fanauthgw-admin-test",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
